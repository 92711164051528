import React from 'react'
import styled from '@emotion/styled'

export const Wrapped = styled.div`
	margin: 0 auto;
	min-height: 500px;
	background: #eee;
	padding-top: 10%;
`
const ThanksPage = () => {
	return (
      <Wrapped>
		<div className="text-center">
		  <h1 className="display-3">Thank You!</h1>
		  <p className="lead"><strong>We've received your submission! You will soon be hearing back from us.</strong></p>
		</div>
	  </Wrapped>
	)
}

export default ThanksPage
